import React, { useContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

import ReactQuill from 'react-quill';

import { useLocation, useNavigate } from 'react-router-dom';

import getI18nService from '../../i18n/i18n';
// import { Context } from '../../store/Store';
import { callFetchData } from '../../common/Fetch';
import userService from '../../api/UserService';
import { coaStatusEnum } from '../../common/metadata/DataEnums';
// import RoundedContainer from './includes/RoundedContainer';
import GuestButton, { GuestButtonLoading } from '../includes/GuestButton';

const i18n = getI18nService();
// const env = window['USERSERVICE_ENV'] || 'dev';
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
const modules = {
  toolbar: false,
  clipboard: {
    matchVisual: false,
  },
};

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SPONSOR_EMAIL = 'SPONSOR_EMAIL';
const TNC = 'TNC';
const ACCESS_CODE = 'ACCESS_CODE';
const widths = {
  [ACCESS_CODE]: '80%',
  [TNC]: '80%',
  [SPONSOR_EMAIL]: 500,
};
const heights = {
  [TNC]: 700,
  [SPONSOR_EMAIL]: 500,
};
const portalTypes = {
  ACCESS_CODE,
  SPONSOR_EMAIL,
  TNC,
};

const forwardTimeout = 1000;

const TermsPassThrough = props => {
  const { portalData } = props;

  let query = useQuery();
  const theme = useTheme();
  // const navigate = useNavigate();
  // const [state, dispatch] = useContext(Context);
  const [editorHtml, setEditorHtml] = useState(null);
  const [forward, setForward] = useState(false);
  const [coaStatus, setCoaStatus] = useState(coaStatusEnum.pending);
  const [counter, setCounter] = useState(0);
  const [coaInterval, setCoaInterval] = useState(null);
  const [accessCode, setAccessCode] = useState('');
  const [showAccessCode, setShowAccessCode] = useState(false);

  useEffect(() => {
    try {
      let content = portalData.tnc;
      if (typeof content === 'string' && content.startsWith('{')) {
        content = JSON.parse(portalData.tnc);
      }
      setEditorHtml(content);
    } catch (error) {
      setEditorHtml(portalData.tnc);
      console.error(error);
    }
  }, [portalData]);

  useEffect(() => {
    const sessionId = query.get('sessionId');
    const forward = query.get('forward');
    if (coaStatus === coaStatusEnum.ack) {
      window.location.href = `/tncAccessSuccess?sessionId=${sessionId}&forward=${forward}`;
    } else if (coaStatus === coaStatusEnum.error) {
      window.location.href = `/errorPassThrough?sessionId=${sessionId}&forward=${forward}`;
    } else if ((coaStatus === coaStatusEnum.nAck || coaStatus === coaStatusEnum.pending) && counter > 5) {
      window.location.href = `/errorPassThrough?sessionId=${sessionId}&forward=${forward}`;
    }
  }, [coaStatus]);

  /**
   * Submit an approval request
   */
  const runCoaCheck = async () => {
    const sessionId = query.get('sessionId');
    const forward = query.get('forward');
    console.log('runCoaCheck');
    if (counter > 5) {
      clearInterval(coaInterval);
      // Tried several time just assume it is ok at this point.
      setCoaStatus(coaStatusEnum.ack);
    } else {
      // const authenticated = true;
      const api = Object.assign({}, userService.FetchSessionStatus, {
        data: { sessionId },
      });
      console.log(`Running COA check for ${counter} time.`);
      const result = await callFetchData(api);
      if (result && result.status <= 204) {
        const { resp } = result;
        if (resp && resp.coaStatus === coaStatusEnum.ack) {
          setCoaStatus(coaStatusEnum.ack);
          clearInterval(coaInterval);
        } else if (resp && resp.coaStatus === coaStatusEnum.error) {
          setCoaStatus(coaStatusEnum.error);
          clearInterval(coaInterval);
        } else {
          setCoaStatus(coaStatusEnum.pending);
        }
      } else {
        setCoaStatus(coaStatusEnum.error);
        clearInterval(coaInterval);
      }
    }
  };

  useEffect(() => {
    if (counter > 0) {
      runCoaCheck();
    }
  }, [counter]);

  const handlePassthrough = async event => {
    const sessionId = query.get('sessionId');
    const forward = query.get('forward');
    const essid = query.get('essid');
    const authenticated = true;
    const portalType = portalTypes.TNC;
    const api = Object.assign({}, userService.UserLogin, {
      data: { sessionId, forward, essid },
      params: { forward, essid },
      body: { authenticated, portalType, forward, essid },
    });

    const result = await callFetchData(api);
    if (result && result.status <= 204) {
      console.log(`DONE forward now to ${forward}`);
      setForward(true);
      const coaInt = setInterval(() => {
        setCounter(cnt => cnt + 1);
      }, 2000);
      setCoaInterval(coaInt);
      // setTimeout(() => {
      //   // window.location.href = forward;
      //   window.location.href = `/tncAccessSuccess?sessionId=${sessionId}&forward=${forward}`;
      // }, forwardTimeout);
    } else {
      console.log(`DONE forward now to ${forward}`);
      // setForward(true);
      // setTimeout(() => {
      //   // window.location.href = forward;
      //   window.location.href = `/tncAccessSuccess?sessionId=${sessionId}&forward=${forward}`;
      // }, forwardTimeout);
    }
  };

  const handleAccessCode = async event => {
    const sessionId = query.get('sessionId');
    const forward = query.get('forward');
    const essid = query.get('essid');
    const authenticated = true;
    const cmd = 'login';
    const portalType = portalTypes.ACCESS_CODE;
    const api = Object.assign({}, userService.UserLogin, {
      data: { sessionId, forward, essid },
      params: { cmd, forward, essid },
      body: { authenticated, portalType, forward, essid, accessCode },
    });

    const result = await callFetchData(api);
    if (result && result.status <= 204) {
      console.log(`DONE forward now to ${forward}`);
      setForward(true);
      const coaInt = setInterval(() => {
        setCounter(cnt => cnt + 1);
      }, 2000);
      setCoaInterval(coaInt);
    } else {
      console.log(`DONE forward now to ${forward}`);
    }
  };

  const handleAcceptAndConnect = async event => {
    if (portalData.portalType === portalTypes.TNC) {
      handlePassthrough(event);
    }
    if (portalData.portalType === portalTypes.ACCESS_CODE) {
      handleAccessCode();
    }
  };

  const getTncContent = withClose => {
    return (
      <Box
        id="test_guest_passThrough"
        sx={{
          height: 250,
          overflow: 'auto',
          borderRadius: 4,
          bgcolor: 'background.paper',
          color: 'text.primary',
          position: 'relative',
          paddingBottom: 1,
        }}
      >
        {withClose && (
          <Tooltip title={i18n.t('Vocabulary.doneReading')}>
            <IconButton
              id="test_guest_passThrough_doneReadBtn"
              sx={{ position: 'absolute', bottom: 0, right: 0, zIndex: 99 }}
              aria-label="delete"
              // size="small"
              color="primary"
            >
              <CheckCircleOutlineIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
        )}
        <Box
          id="test_guest_termsContainer"
          sx={{
            height: '100%',
            overflow: 'auto',
          }}
        >
          <ReactQuill
            style={{ padding: '8px', borderRadius: '8px', flexGrow: 1 }}
            readOnly
            value={editorHtml}
            modules={modules}
            // formats={formats}
            bounds={'.app'}
            placeholder="Start typing"
          />
        </Box>
      </Box>
    );
  };

  const handleClickShowAccessCode = () => setShowAccessCode(showAccessCode => !showAccessCode);
  const handleMouseDownAccessCode = event => {
    event.preventDefault();
  };

  const getAccessCodeContentAlt = () => {
    return (
      <Stack sx={{ width: '100%' }} alignItems="center">
        <FormControl sx={{ m: 1, width: '25ch' }} size="small" variant="outlined">
          <InputLabel htmlFor="access-code">Passcode</InputLabel>
          <OutlinedInput
            id="access-code"
            sx={{ borderColor: 'red !important', borderWidth: 1 }}
            type={showAccessCode ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle access code visibility"
                  onClick={handleClickShowAccessCode}
                  onMouseDown={handleMouseDownAccessCode}
                  edge="end"
                >
                  {showAccessCode ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Passcode"
          />
        </FormControl>
      </Stack>
    );
  };

  const getAccessCodeContent = () => {
    return (
      <Stack sx={{ width: '100%' }} alignItems="center">
        <TextField
          id="passcode"
          label="Passcode"
          size="small"
          value={accessCode}
          onChange={event => setAccessCode(event.target.value)}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
        />
      </Stack>
    );
  };

  const connectDisabled = useMemo(() => {
    if (portalData.portalType === portalTypes.ACCESS_CODE && accessCode.length < 4) {
      return true;
    } else {
      return false;
    }
  }, [accessCode, portalData]);
  return (
    <Box
      sx={{
        minWidth: 400,
        maxWidth: portalData ? widths[portalData.portalType] : widths['TNC'],
        // maxHeight: 720,
        mx: 'auto',
        mt: 3,
      }}
    >
      {portalData && portalData.portalType === portalTypes[ACCESS_CODE] && editorHtml && getAccessCodeContent()}
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
        <Box
          sx={{
            maxHeight: 700,
            minWidth: 400,
            border: 1,
            borderColor: 'grey.200',
            borderRadius: 4,
            boxShadow: 0,
            bgcolor: 'grey.100',
            opacity: 0.8,
            color: 'text.primary',
          }}
        >
          {!editorHtml && <span>Loading...</span>}
          {portalData &&
            (portalData.portalType === portalTypes[TNC] || portalData.portalType === portalTypes[ACCESS_CODE]) &&
            editorHtml &&
            getTncContent()}
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center', pt: 3 }}>
        <GuestButtonLoading
          id="test_guest_acceptBtn"
          disabled={connectDisabled}
          variant="contained"
          size="small"
          onClick={handleAcceptAndConnect}
          loading={forward}
          endIcon={<WifiTetheringIcon />}
          loadingPosition="end"
        >
          {forward ? i18n.t('Vocabulary.connecting') : i18n.t('Vocabulary.agreeAndConnect')}
        </GuestButtonLoading>
        <Typography component="div" variant="caption" sx={{ padding: 2 }}>
          {navigator.userAgent}
        </Typography>
      </Box>
    </Box>
  );
};

TermsPassThrough.propTypes = {
  portalData: PropTypes.object.isRequired,
};

export default TermsPassThrough;
